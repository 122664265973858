<template>
    <div>
    <el-input v-model="input" placeholder="请输入内容"  @input="flite(input)" style="width: 300px;margin-right:20px"></el-input>
    <el-button type="primary" @click="add">新增</el-button>
    <div class="word">敏感词名称</div>
  
     <div class="content" >
         <div class="long" v-for="item in words" :key="item.index">
             <span class="written">{{item.words}}</span>
          <el-amap-ground-image class="fourk" @click="dete(item.id)"></el-amap-ground-image>
         </div>
     </div>
<div style="position:relative;width:720px;height:30px;padding-top:20px">  
          <el-pagination style="position:absolute;right:0"
              @size-change="handleSizeChange"
              @current-change="currentPage"
              :current-page="currentPg"
              :page-sizes="[50, 100, 200, 500]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
      </div>
    </div>
</template>
<script>
// 调用数据库
import { DELETE, QUERY } from "@/services/dao.js";
export default {
    data(){
        return{
           input:"",
           url:'url',
           currentPg:0,
           pageSize:50,
           total:800,
           words:[],
           table:[]
        }
    },
    created(){
        this.getdate(this.pageSize,0);
    },
    methods:{

    ////首次加载
  async  getdate(limit,offset){
         let data = await QUERY( "post","", '  TtSensitivewords(limit: '+limit+', offset: '+offset+') { words id}   TtSensitivewords_aggregate {  aggregate {    count   } }');
      let that=this;
      that.words.splice(0, this.words.length);
      for (let i = 0; i < data.data.TtSensitivewords.length; i++) {
        that.words.push({
            id:data.data.TtSensitivewords[i].id,
            words:data.data.TtSensitivewords[i].words,
        });
      }
       this.total=data.data.TtSensitivewords_aggregate.aggregate.count;
    ///赋值
     that.table.splice(0, this.table.length);
      for (let i = 0; i < data.data.TtSensitivewords.length; i++) {
        that.table.push({
            id:data.data.TtSensitivewords[i].id,
            words:data.data.TtSensitivewords[i].words,
        });
      }
    },
            //前一页
        prevPage(val){
            this.pageNum=val-1;
            this.getdate(this.pageSize,this.pageSize*this.pageNum);
        },
        //下一页
        nextPage(val){
            this.pageNum=val-1;
            this.getdate(this.pageSize,this.pageSize*this.pageNum);
        },
        //当前页
        currentPage(val){
            console.log(val);
            this.pageNum=val-1;
            this.getdate(this.pageSize,this.pageSize*this.pageNum);
        },
        //切换每页条数
        handleSizeChange(val){
            this.pageSize=val;
           this.getdate(this.pageSize,0);
        },
     //c查询
     flite(input) {
      let  that = this;
      //  filter  数组API    includes  字符串API  
      //  和数组中的 includes类似
     that.words = that.table.filter(Val => {
        if (Val.words.includes(that.input)) {
          that.words.push(Val);
          return that.words;
        }
      });
    },
    ///删除数据
    async  dete(id) {
       this.id=id;
       let data = await DELETE( "post","", ' delete_TtSensitivewords(where: {id: {_eq: '+this.id+'}}) {  affected_rows }');
        //alert(data)
         if(data.data.delete_TtSensitivewords.affected_rows>0){
             this.getdate();
            //alert("删除成功!");
             this.$message({
                message: '删除成功!',
                type: 'success'
            });
               this.getdate(this.pageSize,0);
           }
        },

        add(){
              this.$router.push("/words-add");
        },
    }
}
</script>
<style scoped>
.written{
   /* display: inline-block; */
   float: left;
width:80px;
height:31px;
line-height: 31px;
background-color:rgba(255,255,255,1);
border:1px solid rgba(160,160,160,1);
border-radius:15px;
text-align: center;
/* margin-top: 5px; */
/* margin-right: 5px; */
}
.long{
    width:85px;
    height: 40px;
    /* border: 1px solid yellow; */
    display: inline-block;
    /* background-color: white; */
    /* margin-right: 5px; */
}
.long:hover{
    color: tomato;
}
.content{
    margin-top: 20px;
    padding: 10px;
    width: 700px;
    /* height: 300px; */
    border: 1px solid #B5B5B5;
}
.fourk{
    display: inline-block;
    background-image:url('../../../../assets/icon/fork.png');
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    position: relative;
    left: 65px;
    top: -33px;
    /* border: 1px solid red; */
}
.word{
margin-top:20px;
/* background-color:#0084FF; */
width:100px;
height:40px;
line-height: 40px;
/* display: inline-block; */
text-align: center;
border-bottom: 2px solid #0084FF;
/* border-radius: 10px; */
}
</style>